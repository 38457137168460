import React from 'react';
import Helmet from '../project/Helmet';
import ImportData from './import/ImportData';
import ExportData from './import/ExportData';
import { t } from '@lingui/macro';

/**
 * @fero
 */

class ImportExportPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Import/Export`}
            />
            <div>
                <ExportData/>
                <ImportData/>
            </div>
        </React.Fragment>;
    }

}

export default ImportExportPage;