import React from 'react';
import {Trans, t} from '@lingui/macro';
import {getDomain} from '../../../lib/url';
import {Button, Upload, notification} from 'antd';
import { IMPORT_TARGET } from '../../../constants/apiEndpoints';
import Tooltip from '../../general/Tooltip';
import Select from '../../general/Select';
import 'whatwg-fetch';

/**
 * @fero
 */

class ImportData extends React.PureComponent {

	constructor(props) {
        super(props);
        this.state = {
        	importType: null,
        	file: null,
        	loading: false,
        };
    }

	onImportTypeChange = (newValue) => {
		this.setState({importType: newValue});
	};

   	beforeFileUpload = (newFile) => {
   		this.setState({ file: newFile, loading: false });
   		return false;
   	};

   	onFileRemove = (ev) => {
   		this.setState( { file: null, loading: false });
   	};

    onSubmit = (ev) => {
    	const {file, importType} = this.state;

    	this.setState( { loading: true });

        const formData = new FormData();
        formData.append('file',file);
        formData.append('import_type', importType);
    
        fetch(getDomain()+IMPORT_TARGET, {method: 'POST',  body: formData, credentials: 'include'})
        .then((response) => { 
            return response.json()
            .then((json) => {

            	this.setState({
		            file: null, 
		            loading: false,
		        });

                if(json.result != null)
                	notification['success']({ 
						message: json.result, 
						duration: 0, 
					}); 
                
                if(json.error != null)
                	notification['error']({ 
						message: json.error.message,
						duration: 0,
					});
            });
        })
        .catch((ex) => {
        	this.setState({
	            file: null, 
	            loading: false,
	        });

            notification['error']({ 
				message: ex.toString(),
				duration: 0,
			});
        });
    };

    render() {
    	const {importType, file, loading} = this.state;
    	const ready = (importType != null && file != null && loading == false);
    	const importTypes = [
			{value: "product/import", label: <Trans>Import produktov</Trans>},
			{value: "product/import_new_designations", label: <Trans>Import nových označení</Trans>},
			{value: "product/import_list_prices", label: <Trans>Import brutto cien</Trans>},
			{value: "product/import_special_prices", label: <Trans>Import špeciálnych cien</Trans>},
			{value: "product/import_alt_categories", label: <Trans>Import alternatívnych kategórií</Trans>},
			{value: "product/import_params", label: <Trans>Import parametrov produktov</Trans>},
			{value: "product/import_barcodes", label: <Trans>Import čiarových kódov produktov</Trans>},
			{value: "product/import_custom_codes", label: <Trans>Import zákazníckych čísel produktov</Trans>},
			{value: "product/import_stock_params", label: <Trans>Import nastavení skladu</Trans>},
			{value: "product/import_replacements", label: <Trans>Import náhrad produktov</Trans>},
			{value: "product/import_components", label: <Trans>Import komponentov produktov</Trans>},
			{value: "product/import_variants", label: <Trans>Import variantov produktov</Trans>},
			{value: "product/import_relatives", label: <Trans>Import súvisiacich produktov</Trans>},
			{value: "product/import_flags", label: <Trans>Import príznakov produktov</Trans>},
			{value: "product/import_keys", label: <Trans>Import identifikátorov produktov</Trans>},
			{value: "product/import_merge", label: <Trans>Import spájania duplicít</Trans>},
			{value: "category/import", label: <Trans>Import kategórií</Trans>},
			{value: "discount/import_groups", label: <Trans>Import rabatových skupín</Trans>},
			{value: "discount/import_rules", label: <Trans>Import rabatových pravidiel</Trans>},
			{value: "customer/import", label: <Trans>Import organizácií</Trans>},
			{value: "user/import", label: <Trans>Import kontaktov</Trans>},
		];

        return <div className="p-3">
        	<h3><Trans>Import z MS Excel</Trans></h3>
        	<div className="d-flex align-items-center">
        		<span className="px-2 font-weight-bold"><Trans>Zostava:</Trans></span>
        		<Select
        			className="export-type-select"
        			value={importType != null ? importType : undefined}
        			onChange={this.onImportTypeChange} 
        			options={importTypes}
        			placeholder={<Trans>Vyberte ...</Trans>}
                />
                {file == null ? 
		        	<Upload
		        		action=""
						accept='.xlsx'
		        		showFileList={false}
		        		beforeUpload={this.beforeFileUpload}
		        	>
		        		<Button>
		        			<Trans>Vybrať súbor ...</Trans>
		        		</Button>
		        	</Upload>
		        :  <span className="px-2">
		        	<span className="px-2">{file.name}</span>
		        	<Button 
		        		onClick={this.onFileRemove}
		        		icon="cross"
		        	/>
		        	<Tooltip stopPropagationOnClick={!ready} title={<Trans>Nahrať</Trans>}>
			        	<Button
			        		type="primary"
			        		onClick={this.onSubmit}
			        		icon={loading ? "loading" : "upload"}
			        		disabled={!ready}
			        	/>
			        </Tooltip>
		        </span>}
	        	
        	</div>
        </div>;
    }

}

export default ImportData;