import React from 'react';
import PropTypes from 'prop-types';
import {getDomain} from '../../lib/url';
import {EXPORT_TARGET} from '../../constants/apiEndpoints';
import {addQueryParamsToUrl} from '../../lib/url';
import {merge} from '../../lib/object';
import {Button} from 'antd';
import Tooltip from '../general/Tooltip';

/**
 * @fero
 */

class ExportButton extends React.PureComponent {
    static propTypes = {
        what: PropTypes.string,
        manufacturerId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
        categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        title: PropTypes.node.isRequired,
        args: PropTypes.object,
    };

    render() {
        const {what, manufacturerId, customerId, categoryId, title, args, disabled, ...props} = this.props;
        let url = EXPORT_TARGET + '?export_type=' + what;
        if(manufacturerId != null)
        {
            if(Array.isArray(manufacturerId))
                manufacturerId.forEach((mId) => { url += '&id_manufacturer[]=' + mId; });
            else
                url += '&id_manufacturer=' + manufacturerId;
        }

        if(customerId != null)
        {
            if(Array.isArray(customerId))
                customerId.forEach((cId) => { url += '&id_customer[]=' + cId; });
            else
                url += '&id_customer=' + customerId;
        }

        if(categoryId != null)
        {
            if(Array.isArray(categoryId))
                categoryId.forEach((cId) => { url += '&id_product_group[]=' + cId; });
            else
                url += '&id_product_group=' + categoryId;
        }


        return <a href={url}>
            <Tooltip stopPropagationOnClick={disabled} title={title}>
                <Button
                    icon="download"
                    type="primary"
                    disabled={disabled}
                    {...props}
                />
            </Tooltip>
        </a>;
    }

}

export default ExportButton;